import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "makers/pckoubou"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    koubou_wordcloud: file(relativePath: { eq: "pcmaker/koubou_wordcloud.png"}) { ...normalImg },
    koubou: file(relativePath: { eq: "pcmaker/koubou.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`パソコン工房の評判 - リーゾナブルで抜群のスペック`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="koubou_wordcloud" alt="パソコン工房の評判" fadeIn={false} mdxType="Image" />
    <p>{`ある程度パソコンについての知識がある人が、コストパフォーマンスと品質の安定性を追求した結果、老舗のBTOメーカーであるパソコン工房に行きつくということは多い。`}</p>
    <p>{`また、CPUやグラフィックボードといった部品も扱っており自作PCのファンも多いパソコン工房はマウスコンピュータ傘下に入り、調達を共通化することや品質の高いiiyamaモニタを採用することで価格競争力やサポートを含めた品質に磨きをかけてきている。`}</p>
    <p>{`それでは、早速評判を見ていこう。尚、他メーカーと比較検討したい場合は`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカーおすすめランキング`}</Link>{`も併せて参照してほしい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "パソコン工房のラインナップと特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF%E3%81%AE%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%A8%E7%89%B9%E5%BE%B4",
        "aria-label": "パソコン工房のラインナップと特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン工房のラインナップと特徴`}</h2>
    <Lineup {...props} maker="koubou" mdxType="Lineup" />
    <p>{`PC工房はハイスペックな高価格帯モデルを中心としたラインナップを持っており、標準的なデスクトップ/ノートパソコンはもとより、利幅の大きい`}<strong parentName="p"><em parentName="strong">{`ゲーミングPCやクリエイター向けのパソコンに力を入れている`}</em></strong>{`。`}</p>
    <p>{`例えばゲームならば、モンスターハンターやFFなど各ゲームごとに快適に遊べることを検証して保証したゲーム推奨パソコン、クリエイター向けならば音楽制作向け、CAD制作向けなどに最適な構成を提示してくれており、`}<strong parentName="p"><em parentName="strong">{`特定用途を目的としてパソコンを探すには最適なラインナップ`}</em></strong>{`となっている。`}</p>
    <div align="right">>> <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">ラインナップを見る</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" /></div>
    <h2 {...{
      "id": "パソコン工房の評判、総評と項目別評価",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF%E3%81%AE%E8%A9%95%E5%88%A4%E3%80%81%E7%B7%8F%E8%A9%95%E3%81%A8%E9%A0%85%E7%9B%AE%E5%88%A5%E8%A9%95%E4%BE%A1",
        "aria-label": "パソコン工房の評判、総評と項目別評価 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン工房の評判、総評と項目別評価`}</h2>
    <RaderChart maker='koubou' className={'top-margin'} mdxType="RaderChart" />
    <p><strong parentName="p"><em parentName="strong">{`ハイエンドなパソコンが圧倒的に安い`}</em></strong>{`というのがパソコン工房の特徴である。特に得意のゲーミングPC、クリエイティブ用途のPCでは全メーカー中最安となるケースも多い。`}</p>
    <p>{`標準的なノートパソコンやデスクトップではDellやLenovoといった海外メーカーに価格では一歩及ばないが、短納期であることや、LINEや電話での故障対応なども充実していることから安心感がありパソコン工房が選ばれることも多い。`}</p>
    <p>{`あるいは`}<strong parentName="p"><em parentName="strong">{`NECや富士通、Dynabookなど日系大手メーカーが高すぎると感じ、使い方のサポートなどは不要なので価格を安くしたいという理由でパソコン工房に流れる`}</em></strong>{`ことも多い。`}</p>
    <AccentBox title="NECパソコンの項目別評価" mdxType="AccentBox">
  <li><span className="bold">コストパフォーマンス</span>・・・コスト競争力は非常に高い。</li>
  <li><span className="bold">サポート</span>・・・<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=13526&murl=https%3A%2F%2Fwww.pc-koubou.jp%2Fshop_guide%2Finformation.php" target="_blank" rel="nofollow noopenner">サポートページ</a>からチャット、LINE、24時間電話相談が無料。使い方というよりは調子が悪くなった時のトラブル対応メインと考えると良い。</li>
  <li><span className="bold">デザイン</span>・・・デザインを意識してパソコン工房のパソコンを購入する人は聞いたことが無い</li>
  <li><span className="bold">人気</span>・・・<b>当サイトでの人気は非常に高い</b>。購入層のリテラシーも高いのかサクサクと売れていく。</li>
  <li><span className="bold">安定性</span>・・・老舗のBTOでかつ、勢いのあるマウスコンピュータ系列のため持続性のあるメーカーといえる。</li>
    </AccentBox>
    <h2 {...{
      "id": "実際に売れているモデルはこれ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E5%A3%B2%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AF%E3%81%93%E3%82%8C",
        "aria-label": "実際に売れているモデルはこれ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に売れているモデルはこれ`}</h2>
    <p>{`ノートパソコン、デスクトップパソコン、ゲーミングPCがそれぞれ同じぐらいの割合で満遍なく売れており、平均購入単価は12万円といったところ。`}</p>
    <p>{`ゲーミングPCがPC市場全体を占める割合は高くないことを考慮すると、20万程度はするにも関わらず`}<strong parentName="p"><em parentName="strong">{`ゲーミングPCは非常に売れ行きが良い`}</em></strong>{`と言える。`}<em parentName="p">{`LEVEL∞(レベルインフィニティ)`}</em>{`というブランドで発売している。`}</p>
    <p>{`リアルタイムの売れ筋商品としては下記で確認できる。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=13526&murl=https%3A%2F%2Fwww.pc-koubou.jp%2Fpc%2Franking_all.php" target="_blank" rel="nofollow noopenner">パソコン工房売れ筋ランキング</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=1042756.1&type=10" />
    </SmallBox>
    <h2 {...{
      "id": "パソコン工房の３大悪評",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF%E3%81%AE%EF%BC%93%E5%A4%A7%E6%82%AA%E8%A9%95",
        "aria-label": "パソコン工房の３大悪評 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン工房の３大悪評`}</h2>
    <p>{`悪評は購入時の期待値と実際の乖離から生じるものである。よって`}<strong parentName="p"><em parentName="strong">{`購入前に齟齬が出そうな点をあらかじめ抑えておくことが重要`}</em></strong>{`となる。ここではネットでの評判を抽出し、その妥当性についてコメントしていく。`}</p>
    <h3 {...{
      "id": "納期が遅い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B4%8D%E6%9C%9F%E3%81%8C%E9%81%85%E3%81%84",
        "aria-label": "納期が遅い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`納期が遅い`}</h3>
    <p>{`全くそんなことは無い。確かに部品が届いていない、製造が追いついていないなどで製品によっては１ヵ月以上の納期が発生するものもある。`}</p>
    <p>{`ただ基本的には`}<strong parentName="p"><em parentName="strong">{`カスタマイズも含めて４日もあれば出荷される`}</em></strong>{`ため、海外メーカーと比べると十分に早い。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`全ての納期の目安は出荷予定日として製品の基本構成欄に記載`}</em></strong>{`されており、そこを参考にすれば良いだけである。`}</p>
    <h3 {...{
      "id": "壊れやすい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A3%8A%E3%82%8C%E3%82%84%E3%81%99%E3%81%84",
        "aria-label": "壊れやすい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`壊れやすい`}</h3>
    <p>{`国内組み立てを行っている、ノウハウを持った老舗である、マウスコンピュータとの部品の共同調達により粗悪な部品を掴む可能性が低い等の理由から`}<strong parentName="p"><em parentName="strong">{`壊れやすい理由はない`}</em></strong>{`。`}</p>
    <p>{`ただ、ノートパソコンの場合、防水仕様や落としたときのダメージといった要素に対してはNECや富士通などの日系大手メーカーの方が強い。まあとはいえ特に壊れやすいのはモバイルノートなのだけれど、パソコン工房はモバイルノート売っていないので問題はない。`}</p>
    <p>{`14インチのパソコンも扱っているが、常日頃から持ち歩くことをメインに考えていてかつ、満員電車などハードな環境を想定しているのならば、より堅牢性の高いパソコンを検討すると良い。`}</p>
    <h3 {...{
      "id": "修理費が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BF%AE%E7%90%86%E8%B2%BB%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "修理費が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`修理費が高い`}</h3>
    <p><a parentName="p" {...{
        "href": "https://pc-support.unitcom.co.jp/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`総合サポートサイト`}</a>{`で凡その修理費が分かるが、確かに探せばもう少し安いところは見つかるだろう。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`高いと感じた場合は他のサイトと相見積もりを取ることをおすすめする`}</em></strong>{`。引越しであれ、家の清掃作業であれ相見積もりで比較して安いところを探すのは基本である。`}</p>
    <p>{`ハードウェア的な作りはパソコン工房のパソコンは簡素であるので、`}<strong parentName="p"><em parentName="strong">{`パソコン工房でないと修正できないということはない`}</em></strong>{`。`}</p>
    <p>{`ただパソコン工房はパーツを扱っていてかつ、全国に店舗を構えているため部品の融通が効きやすい。どんな不具合であれ何とかしてくれる体制は整っているといえる。`}</p>
    <p>{`ともあれ、いずれにせよ保証期間を過ぎた場合の話である。基本的に`}<strong parentName="p"><em parentName="strong">{`１年間はメーカー保証がついてくるので無償修理に応じられる`}</em></strong>{`
。`}</p>
    <h2 {...{
      "id": "パソコン工房の評判まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF%E3%81%AE%E8%A9%95%E5%88%A4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "パソコン工房の評判まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン工房の評判まとめ`}</h2>
    <p>{`以上、`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスに圧倒的な強みを持ち、製品不良に対するサポートも充実`}</em></strong>{`。`}</p>
    <p>{`初期ソフトには余計なものが入っておらず、使い方は自分でインターネットで調べて学んでいくというタイプの人にはピッタリなパソコンメーカーである。`}</p>
    <p>{`頻繁にセールも行っているので覗いていくのも良いだろう。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" />
    </SmallBox>
    <h2 {...{
      "id": "他社との比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BB%96%E7%A4%BE%E3%81%A8%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "他社との比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他社との比較`}</h2>
    <p>{`パソコンの種類や用途により得意なメーカーは分かれるため、当サイトでは選択肢に応じたランキングを制作している。`}</p>
    <p>{`デフォルトは当サイトの人気順であるが、探している種類やこだわりがあれば選択し、自分にあったパソコンを探してほしい。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`パソコン工房と似ているメーカーとしてはマウスコンピュータやフロンティアを同時に確認`}</em></strong>{`したい。リンクをクリックすると直販サイトへ移動します。`}</p>
    <MakerRanking highlight='koubou' mdxType="MakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      